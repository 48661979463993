<template>
  <section class="type">
    <div class="top">
      <span class="title">付款方式</span>
      <!-- <span>目前台灣Pay服務異常，請使用其他付款方式</span> -->
      <a v-if="systemNotice.message" @click="$emit('showSystemNotice')">
        <font-awesome-icon icon="fa-solid fa-triangle-exclamation" size="lg"></font-awesome-icon>
      </a>
    </div>
    <div class="center" v-for="i in rowCount" :key="i">
      <a 
        v-for="(method, index) in supportedPaymentMethods.slice((i - 1) * itemsPerRow, i * itemsPerRow)"
        :key="index"
        v-show="method.isBrowserSupported && method.isEnabled "
        @click="onSelectPaymentMethod(method)"
        :class="{'active': selectedMethod && method.paymentServiceId === selectedMethod.paymentServiceId}"> <!-- class="active" -->
        <font-awesome-icon v-if="method.paymentService==='offline'" icon="fa-solid fa-money-bill-wave"  />
        <img v-else-if="method.icon" :src="method.icon" />
        <font-awesome-icon v-else icon="fa-solid fa-credit-card" />
        <span v-if="!method.icon">{{ method.label }} </span>
        <!-- <div>
          <span>{{ method.label }}</span>
          <span v-if="showPaymentName">{{ method.name }}</span>
        </div> -->
        <font-awesome-icon v-if="method.paymentService!=='offline' && !method.icon" icon="fa-solid fa-chevron-right" size="sm" />
      </a>
    </div>
  </section>
  <!-- <div> 
    <div class="center" v-for="i in rowCount" :key="i">
      <a 
        v-for="(method, index) in supportedPaymentMethods.slice((i - 1) * itemsPerRow, i * itemsPerRow)"
        :key="index"
        v-show="method.isBrowserSupported && method.isEnabled "
        @click="onSelectPaymentMethod(method)"
        :class="{'active': selectedMethod && method.paymentServiceId === selectedMethod.paymentServiceId}"> 
        <font-awesome-icon v-if="method.paymentService==='offline'" icon="fa-solid fa-money-bill-wave" size="md" />
        <img v-else-if="method.icon" :src="method.icon" />
        <font-awesome-icon v-else icon="fa-solid fa-credit-card" size="md" />
        <div>
          <span>{{ method.label }}</span>
          <span v-if="showPaymentName">{{ method.name }}</span>
        </div>
      </a>
    </div>
  </div>  -->
  <!-- <div>
    <a 
      v-for="(method, index) in paymentMethods"
      :key="index"
      class="item flexH width middle" 
      v-show="method.isBrowserSupported && method.isEnabled "
      @click="onSelectPaymentMethod(method)"
    >
        <img v-if="method.icon" :src="method.icon" />
        <font-awesome-icon v-else icon="fa-solid fa-credit-card" size="lg" />
        <span class="fM">{{method.label}}</span>
        <span v-if="showPaymentName" class="fM">{{method.name}}</span>
    </a>
  </div> -->
</template>
<script>
import { mapState, mapGetters } from 'vuex';
// import moment from 'moment-timezone';
// import Spinner from '@/components/Spinner';

export default {
  name: 'AvailablePaymentMethod',
  // components: {
  //   Spinner,
  // },
  props: {
    storeCode: String,
  },
  data() {
    return {
      apiHost: process.env.VUE_APP_API_HOST,
      merchantId: process.env.VUE_APP_MERCHANT_ID,
      itemsPerRow: 2,
      selectedMethod: null,
    };
  },
  created: function () {
    
  },
  mounted: function () {
    console.log('success!');
  },
  computed: {
    ...mapState(['user']),
    ...mapGetters({
      userPoints: 'payment/getUserPoints',
      shopSupportedPoints: 'payment/getShopPoints',
      availablePointTypes: 'payment/getAvailablePointTypes',
      appliablePointTypes: 'payment/getApplicablePointTypes',
      paymentMethods: 'payment/getSupportedPaymentMethods',
      systemNotice: 'payment/getSystemNotice',
    }),
    showPaymentName(){
      return false;
      // return process.env.VUE_APP_ENV !== 'production';
    },
    supportedPaymentMethods () {
      return this.paymentMethods.filter(method => method.isBrowserSupported && method.isEnabled );
    },
    rowCount() {     
      return this.supportedPaymentMethods?.length ? Math.ceil(this.supportedPaymentMethods.length / this.itemsPerRow) : 0;
    }
  },
   watch: {
   
  },
  methods: {
    onSelectPaymentMethod(method) {
      this.selectedMethod = {...method};
      
      let types = ['direct', 'credit'];
      let showCardForm = false;
      let found = null;
      if (method.paymentType) {
        found = types.find((t) => t === method.paymentType);
      }
      if (method.paymentService === 'tappay' && found) {
        showCardForm = true;
      }
      this.$emit('selected', {
        method: method,
        showCardForm: showCardForm
      });
    },
    
  },
};
</script>

<style scoped src="@/assets/css/app.css"></style>
<style scoped src="@/assets/css/unique.css"></style>
<style scoped src="@/assets/css/payment.css"></style>
