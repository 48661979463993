<template>
  <section class="point">
    <div class="top">
      <!-- <span v-if="availablePointTypes.length === 1" class="title"> {{ availablePointTypes[0].pointName }} </span> -->
      <span v-if="availablePointTypes.length === 1" class="title"> 可用點數 </span>
      <span v-else class="title">點數</span>
      <img v-if="selectedPoint && selectedPoint.pointLogo" :src="selectedPoint.pointLogo">
      <img v-else src="@/assets/icon/pointP.svg">
      <!-- 點數總額 -->
      <span v-if="selectedPoint"> {{ selectedPoint.availablePointBalance || 0}} </span>
    </div>
    <div v-if="selectedPoint && selectedPoint.unavailablePointBalance" class="bottom">
      <span> （限定點 {{ selectedPoint.unavailablePointBalance }}，本店<span style="color:red">不適用</span>限定點） </span>
    </div>
    <div v-show="availablePointTypes.length >1" class="center" v-for="i in rowCount" :key="i">
      <a 
       v-for="(point, index) in availablePointTypes.slice((i - 1) * itemsPerRow, i * itemsPerRow)"
        :key="'available-' + index"
        @click="onSelectPoint(point)"
        :class="{'active': selectedPoint && point.pointCode === selectedPoint.pointCode }">
          <img :src="point.pointLogo">
          <div>
              <span>{{ point.pointName }}</span>
              <!-- <span>{{ point.pointBalance }}</span> -->
              <span>{{ point.availablePointBalance }}</span>
          </div>
      </a>
    </div>
    <div class="input">
      <span>NT$</span>
      <input 
        ref="pointAmount" 
        type="number" 
        placeholder="" 
        v-model.number="amount" 
        @input="onPointAmountChange"
        pattern="^//d+$"
        :disabled="disableInputBox"
      >
    </div>
    <div class="bottom">
        <div v-if="selectedPoint">
          <img :src="selectedPoint.pointLogo">
          <span>{{selectedPoint.pointsToCurrency.points}} = NT${{selectedPoint.pointsToCurrency.currency}}</span>
        </div>
        <div v-if="showToggleAll">
          <label class="check">
            <input 
              type="checkbox" 
              name="checkbox1" 
              v-model="toggleAllPoints"
              @change="onToggleAllPoints"> 
            <span class="checkmark checkbox"></span>
            <span>使用全部</span>
          </label>
        </div>
    </div>
  </section>
  <!-- <div> 
    <div class="center" v-for="i in rowCount" :key="i">
      <a 
       v-for="(point, index) in availablePointTypes.slice((i - 1) * itemsPerRow, i * itemsPerRow)"
        :key="'available-' + index"
        @click="onSelectPoint(point)"
        :class="{'active': selectedPoint && point.pointCode === selectedPoint.pointCode }">
          <img :src="point.pointLogo">
          <div>
              <span>{{ point.pointName }}</span>
              <span>{{ point.pointBalance }}</span>
          </div>
      </a>
    </div>
  </div>  -->
  <!-- <div>
    <a
      v-for="(point, index) in availablePointTypes"
      :key="'available-' + index"
      class="item flexH width"
      @click="onSelectPoint(point)"
    >
      <div class="flexV width">
        <span class="fM"> {{ point.pointName }} </span>
        <span class="fS gray">
          {{ point.pointsToCurrency.points }} 點 = NT$
          {{ point.pointsToCurrency.currency }}
        </span>
        <span v-if="point.description" class="fS gray">
          {{ point.description }}
        </span>
      </div>
      <span class="fL primary"> {{ point.pointBalance }}</span>
    </a>
  </div> -->
</template>
<script>
import { mapState, mapGetters } from 'vuex';
// import moment from 'moment-timezone';
// import Spinner from '@/components/Spinner';

export default {
  name: 'AvailablePointList',
  // components: {
  //   Spinner,
  // },
  props: {
    storeCode: String,
    storeId: Number,
    showToggleAll: Boolean,
    pointAmount: Number,
    toggleAll: Boolean, 
    inputFocus: Boolean,
    disableInputBox: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      apiHost: process.env.VUE_APP_API_HOST,
      merchantId: process.env.VUE_APP_MERCHANT_ID,
      itemsPerRow: 2,
      amount: null,
      selectedPoint: null,
      toggleAllPoints: false,
    };
  },
  created: function () {
    this.getUserPoints()
      .then((res) => {
        this.$store.commit('payment/updateUserPoints', res);
      })
      .catch((err) => {
        console.log('err: ', err);
      });
    this.getAppointedStoreInfo()
      .then((res) => {
        console.log('getAppointedStoreInfoRes: ', res);
        this.$store.commit('payment/updateStoreInfo', res);
        this.$store.commit('payment/updateSupportedPointTypes', res.points);
        // if (res.points.length === 1) { // 只支援一種點數時，直接選取
        //   this.onSelectPoint(this.availablePointTypes[0])
        // }
      })
      .catch((err) => {
        console.log('err: ', err);
        this.$router.push('/error');
      });
  },
  mounted: function () {
    console.log('success!');
    // this.amount = this.pointAmount;
  },
  computed: {
    ...mapState(['user']),
    ...mapGetters({
      userPoints: 'payment/getUserPoints',
      shopSupportedPoints: 'payment/getShopPoints',
      availablePointTypes: 'payment/getAvailablePointTypes',
      appliablePointTypes: 'payment/getApplicablePointTypes',
    }),
    rowCount() {     
      return this.availablePointTypes?.length ? Math.ceil(this.availablePointTypes.length / this.itemsPerRow) : 0;
    },
  },
  watch: {
    availablePointTypes: {
      handler(newVal) {
        if (newVal?.length === 1) {
          this.onSelectPoint(newVal[0])
        }
      },
    },
    pointAmount: {
      handler(newVal) {
        this.amount = newVal;
      },
    },
    toggleAll: {
      handler(newVal) {
        this.toggleAllPoints = newVal;
      },
    },
  },
  methods: {
    onSelectPoint(point) {
      console.log('onSelectPoint:' + JSON.stringify(point));
      this.selectedPoint = Object.assign({}, point);
      this.$emit('selected', point);
      if (this.inputFocus) {
        
        this.$nextTick(function(){
          this.$refs.pointAmount.focus();
        });
      }
    },
    onToggleAllPoints() {
      console.log('onToggleAllPoints');
      this.$emit('toggleAll', this.toggleAllPoints);
    },
    onPointAmountChange($event) {
      // console.log('[point] onPointAmountChange:' + this.amount)
      const val = $event.target.value.replace(/[^\d]/g, ""); // strips letters, keeps numbers

      if (val === this.amount) {
        $event.target.value = Number(val);
      } else {
        this.amount = Number(val);
      }
      this.$emit('amountChanged', this.amount || 0);
    },
    getUserPoints() {
      var config = {
        method: 'get',
        url: `${this.apiHost}/points/v1/merchants/${this.merchantId}/members/${this.user.userId}/points`,
        params: {
          "store_id": this.storeId
        },
        headers: {},
      };

      return this.$http(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          return response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getAppointedStoreInfo() {
      console.log(this.storeCode);
      var config = {
        method: 'get',
        url: `${this.apiHost}/stores/v1/merchants/${this.merchantId}/store-codes/${this.storeCode}`,
        headers: {},
      };

      return this.$http(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          return response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // onlyNumber ($event) {
    //    const val = $event.target.value.replace(/[^\d]/g, ""); // strips letters, keeps numbers
    //   // console.log('val:', val, this.consumptionData.orderAmount)
    //   if (val === this.consumptionData.orderAmount) {
    //     $event.target.value = val
    //   } else {
    //     this.consumptionData.orderAmount = val
    //   }
    // }
  },
};
</script>

<style scoped src="@/assets/css/app.css"></style>
<style scoped src="@/assets/css/unique.css"></style>
<style scoped src="@/assets/css/payment.css"></style>
