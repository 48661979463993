import store from '@/store';

export default {
  name: 'DDPG',
  data() {
    return {
      sdk: null,
      sdkInitialized: false,
      supportedPaymentMethods: [],
    };
  },

  created() {},

  methods: {
    initDDPGSDK(config) {
      // sdk = new DDPGSdk({
      //   mode: 'sandbox',
      //   tappayAppId: 12867,
      //   tappayAppKey: 'app_8rd2JX9GLGqoNPZPo07DI9XJP1n2X6klgOJZj5Y8eHggDTIjm44fR5UJCeJe',
      //   merchantId: 'D_pxmE8ML12'
      // });
      console.log('init DDPG SDK');
      this.sdk = new window.DDPGSdk(config);
      // Demo: how to fetch list of supported payment methods of the merchant.

      // Init TapPay
      this.sdk.init();
      // Init card-number form validation via SDK.
      this.sdk.cardFormSetup({
        fields: {
          number: {
            element: '#input-card-number',
            placeholder: '**** **** **** ****',
          },
          expirationDate: {
            element: document.getElementById('input-exp-date'),
            placeholder: 'MM / YY',
          },
          ccv: {
            element: '#input-ccv',
            placeholder: 'CVC / CVV',
          },
        },
        styles: {
          '.valid': {
            'color': '#333333'
          },
          '.invalid': {
            'color': 'red'
          },
        }
        // styles: {
        //   'input': {
        //     'width': '100%',
        //     'text-align': 'end',
        //     'font-size': 'clamp(1.2rem, 5vw, 1.3rem)',
        //     'margin': '20px',
        //     'padding': '20px'
        //   },
        //   'input.ccv': {
        //     'width': '100%',
        //     'text-align': 'end',
        //     'font-size': 'clamp(1.2rem, 5vw, 1.3rem)',
        //     'margin': '5px 0',
        //     'padding': '5px'
        //   },
        //   'input.expiration-date': {
        //     'width': '100%',
        //     'text-align': 'end',
        //     'font-size': 'clamp(1.2rem, 5vw, 1.3rem)',
        //     'margin': '5px 0',
        //     'padding': '5px'
        //   },
        //   'input.card-number': {
        //      'width': '100%',
        //     'text-align': 'end',
        //     'font-size': 'clamp(1.2rem, 5vw, 1.3rem)',
        //     'margin': '5px 0',
        //     'padding': '5px'
        //   },
        // }
      });

      this.getSupportedPaymentMethods();
      // // If the browser does not support the error, this will catch error.
      // this.sdk.checkPaymentAvailability('tappay', 'direct')
      // .then((res) => {
      //   console.log('checkPaymentAvailability:', res)
      // })
      // .catch(err => {
      //   console.error(`TapPay Direct (credit card) is not supported.`, err);
      // });
    },
    getSupportedPaymentMethods() {
      // Demo: how to fetch list of supported payment methods of the merchant.
      this.sdk
        .getSupportedPaymentMethods()
        .then((result) => {
          console.log(
            'List of supported payment methods:' + JSON.stringify(result)
          );
          if (Array.isArray(result)) {
            this.supportedPaymentMethods = result;
          } else {
            this.supportedPaymentMethods = result?.methods || [];
          }
          let pros = [];
          
          this.supportedPaymentMethods.forEach((method) => {
            if (method.paymentService && method.paymentType) {
              pros.push(
                this.checkPaymentAvailability(
                  method.paymentService,
                  method.paymentType
                )
              );
            }
          });
          if (pros.length) {
            Promise.all(pros)
              .then((res) => {
                this.supportedPaymentMethods.map((obj, index) => {
                  obj.isBrowserSupported = res[index];
                  return obj;
                });
              })
              .catch((err) => {
                console.log('getSupportedPaymentMethods', err);
              })
              .finally(() => {
                store.commit(
                  'payment/updateSupportedPaymentMethods',
                  this.supportedPaymentMethods
                );
                console.log(
                  'getSupportedPaymentMethods promise done.',
                  JSON.stringify(this.supportedPaymentMethods)
                );
              });
          }
        })
        .catch((e) => {
          console.log('payment/getSupportedPaymentMethods failed', e);
        })
        .finally(() => {
          this.sdkInitialized = true;
        });
    },
    checkPaymentAvailability(service, type) {
      // If the browser does not support the error, this will catch error.
      console.log(`checkPaymentAvailability ${service}-${type}`);
      // if (!service || !type) return false;
      return this.sdk
        .checkPaymentAvailability(service, type)
        .then((res) => {
          console.log('checkPaymentAvailability:', res);
          return true;
        })
        .catch((err) => {
          console.error(`${service}-${type} is not supported.`, err);
          return false;
        });
    },
    getPayIndex(payConfig) {
      console.log('[mixins]: getPayIndex:' + JSON.stringify(payConfig), this.sdk);
      return this.sdk
        .getIndex(payConfig)
        .then((index) => {
          console.log(`Got DDPG index`, index);
          return index;
          // Use DDPG API to proceed to pay.
        })
        .catch((e) => {
          console.error('getPayIndex failed:', e);
          throw e;
        });
    },
    pay(payConfig) {
      // Go get an "index" of DDPG.
      this.sdk.getIndex(payConfig).then((index) => {
        console.log(`Got DDPG index`, index);
        // Use DDPG API to proceed to pay.
      });
    },
    initApplePay(amount){
      return this.sdk.TapPayInitApplePay(amount)
      .catch((e)=>{
        console.log('[DDPG] initApplePay failed', e)
        throw e;
      })
    },
    getCardFormStatus() {
      return this.sdk.TapPayGetCardFormStatus();
    }
  },
};
