<template>
  <div id="app" class="payment">
      <!-- class: fixed, mobile -->
      <!-- Loading Spinner -->
      <div class="spinner" v-if="spin"><div><div></div></div></div>
      <!-- <Spinner :spin="spin" /> -->
      <!-- Header -->
      <section class="head">
        <div class="bg"></div>
        <div class="left">
          <a @click.prevent="goBack()">
            <font-awesome-icon icon="fa-solid fa-chevron-left" size="lg" />
          </a>
        </div>
        <span>{{ storeInfo.storeName }}</span>
        <div class="right"></div>
        <!-- <div class="center">
          <span>{{ storeInfo.storeName }}</span>
          <span class="price">NT${{this.consumptionData.amount}}</span>
        </div> -->
      </section>

      <!-- Footer -->
      <section class="foot">
        <button
          :class="{ disabled: !this.consumptionData.amount }"
          @click.prevent="popConfirm()"
        >
          <span>支付</span>
          <!-- <img src="@/assets/icon/pointW.svg"> -->
          <img
            v-if="selectedPoint && selectedPoint.pointLogoW"
            :src="selectedPoint.pointLogoW"
          />
          <span> {{ consumptionData.amount }} </span>
          <!-- <img v-if="selectedPoint && selectedPoint.pointLogo" :src="selectedPoint.pointLogo" /> -->
          <!-- <span >（ ＝</span>
          <img src="@/assets/icon/pointW.svg">
          <span>{{ usedPoints }} ）</span> -->
        </button>
      </section>
      <!-- Main -->
      <section class="main">
        <section class="account">
          <div>
            <span v-if="this.user && this.user.displayName">{{this.user.displayName}} 正在付款</span>
            <span v-if="expiredTime">請於{{expiredTime}}前完成付款</span>
          </div>
        </section>
        <AvailablePointList
          :store-code="storeCode"
          :store-id="orderInfo.store.storeId"
          :show-toggle-all="false"
          :input-focus="false"
          :disable-input-box="true"
          :point-amount="consumptionData.amount"
          @selected="onPointSelected"
          @amountChanged="onPointAmountChange"
        />
        <section class="keyboard">
          <div>
            <a
              v-for="value in [1, 2, 3]"
              :key="'col1' + value"
              @click="onKeyPressed(value)"
            >
              {{ value }}
            </a>
          </div>
          <div>
            <a
              v-for="value in [4, 5, 6]"
              :key="'col1' + value"
              @click="onKeyPressed(value)"
            >
              {{ value }}
            </a>
          </div>
          <div>
            <a
              v-for="value in [7, 8, 9]"
              :key="'col1' + value"
              @click="onKeyPressed(value)"
            >
              {{ value }}
            </a>
          </div>
          <div>
            <a class="minor" @click="onKeyPressed('clear')"> 清除 </a>
            <a @click="onKeyPressed(0)"> 0 </a>
            <a class="minor" @click="onKeyPressed('del')">
              <font-awesome-icon icon="fa-solid fa-delete-left" size="lg" />
            </a>
          </div>
        </section>
      </section>
      <!-- 訊息popup -->
      <section id="popNotice" class="pop notice">
        <div class="popBody">
          <div>
            <div class="notice-close icon">
              <font-awesome-icon v-if="noticeType==='success'" icon="fa-regular fa-circle-check" size="5x" />
              <font-awesome-icon v-else-if="noticeType==='error'" icon="fa-regular fa-circle-xmark" size="5x" />
              <div v-else class="orange">
                <font-awesome-icon icon="fa-solid fa-exclamation" size="3x" />
              </div>
            </div>
            <span class="title">{{ noticeTitle }}</span>
            <span>{{ noticeMessage }}</span>
          </div>
          <button class="notice-submit">
            <span>確認</span>
          </button>
        </div>
      </section>
      <!-- 確認popup -->
      <section
        id="popConfirm"
        class="pop confirm"
        @click.prevent="popConfirmClose"
      >
        <div class="popBody">
          <span class="title">即將支付</span>
          <div>
            <span class="cost">NT${{ consumptionData.amount }}</span>
            <div v-if="usedPoints">
              <!-- <span>=</span> -->
              <img :src="selectedPoint.pointLogo" />
              <span>{{ usedPoints }} </span>
            </div>
          </div>
          <div>
            <span class="subTitle">給</span>
            <span class="title">{{ storeInfo.storeName }}</span>
          </div>
          <div class="buttons">
            <button @click.prevent="popConfirmClose">
              <span>取消</span>
            </button>
            <button @click.prevent="popConfirmSubmit">
              <span>確定送出</span>
            </button>
          </div>
        </div>
      </section>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import moment from 'moment-timezone';
// import Spinner from '@/components/Spinner';
import AvailablePointList from '@/components/AvailablePointList';

export default {
  name: 'PointPayment',
  components: {
   // Spinner,
    AvailablePointList,
  },
  data() {
    return {
      apiHost: process.env.VUE_APP_API_HOST,
      merchantId: process.env.VUE_APP_MERCHANT_ID,
      consumptionData: {
        // use: [],
        amount: 0, // 訂單金額
      },
      token: this.$route.query.token,
      storeCode: this.$route.query.storeCode,
      //   storeInfo: {
      //     storeId: '',
      //     storeName: '',
      //     resourceEntityType: '',
      //     resourceEntityId: '',
      //   },
      spin: false,
      isProcessing: false,
      orderInfo: null,
      selectedPoint: null, //選擇使用的點數
      noticeMessage: null,
      noticeTitle: null,
      noticeType: 'warning', // success, error, warning
      // toggleAllPoints: false,
    };
  },
  beforeRouteEnter(to, from, next) {
    // called before the route that renders this component is confirmed.
    // does NOT have access to `this` component instance,
    // because it has not been created yet when this guard is called!
    console.log(to, from);
    next((vm) => {
      let that = vm;
      if (!to.query.token) {
        // 沒有 token
        next('/consumption-error');
      }
      if (that.user && that.user.userId) {
        // 確認是否在付款中，是否過期 & token是否存在
        that
          .getOrderInfoByToken(to.query.token)
          .then((getOrderInfoByTokenRes) => {
            console.log(
              'getOrderInfoByTokenRes:' + JSON.stringify(getOrderInfoByTokenRes)
            );
            that.orderInfo = { ...getOrderInfoByTokenRes.data };
          })
          .catch((err) => {
            console.error('failed to get order info by token', err);
            next('/consumption-error');
          });
      } else {
        next('/error');
      }
    });
  },
  //   created: function () {
  //     this.getUserPoints()
  //       .then((res) => {
  //         this.$store.commit('updateUserPoints', res);
  //         this.points = JSON.parse(JSON.stringify(this.userPoints));
  //         console.log('this.points: ', this.points);
  //         this.$store.commit('payment/updateUserPoints', res);
  //       })
  //       .catch((err) => {
  //         console.log('err: ', err);
  //       });
  //     this.getAppointedStoreInfo()
  //       .then((res) => {
  //         console.log('getAppointedStoreInfoRes: ', res);
  //         Object.assign(this.storeInfo, res);
  //         this.$store.commit('payment/updateSupportedPointTypes', res.points);
  //       })
  //       .catch((err) => {
  //         console.log('err: ', err);
  //         this.$router.push('/error');
  //       });
  //   },
  mounted: function () {
    console.log('success!');
    // 本機測試時，如果修改程式，頁面不會reload，此時orderInfo會是null，DDPG SDK也不會init
    if (!this.orderInfo && this.token) {
      this.getOrderInfoByToken(this.token)
        .then((getOrderInfoByTokenRes) => {
          console.log(
            'getOrderInfoByTokenRes:' + JSON.stringify(getOrderInfoByTokenRes)
          );
          this.orderInfo = { ...getOrderInfoByTokenRes.data };
        })
        .catch((err) => {
          console.error('failed to get order info by token', err);
          this.$router.push('/consumption-error');
        });
    }
  },
  computed: {
    ...mapState(['user', 'userInfo']),
    ...mapGetters({
      userPoints: 'getTyUserPoints',
      isTokenExpired: 'payment/getIsTokenExpired',
      availablePointTypes: 'payment/getAvailablePointTypes',
      appliablePointTypes: 'payment/getApplicablePointTypes',
    }),
    storeInfo() {
      return this.orderInfo?.store || {};
    },
    checkPoints() {
      return this.selectedPoint && this.selectedPoint.availablePointBalance
        ? this.selectedPoint.availablePointBalance < this.usedPoints
        : false;
      // return this.selectedPoint && this.selectedPoint.pointBalance
      //   ? this.selectedPoint.pointBalance < this.usedPoints
      //   : false;
    },
    // maxPointAmount() {
    //   return (
    //     Math.min(
    //       this.selectedPoint.redeemableDollars,
    //       this.consumptionData.amount
    //     ) || 0
    //   );
    // },
    usedPoints() {
      let points =
        this.selectedPoint && this.selectedPoint.pointsToCurrency
          ? (this.consumptionData.amount /
              this.selectedPoint.pointsToCurrency.currency) *
            this.selectedPoint.pointsToCurrency.points
          : this.consumptionData.amount;
      return points;
    },
    expiredTime() {
      return this.orderInfo.expirationTs
        ? moment.unix(this.orderInfo.expirationTs).format('MM/DD HH:mm')
        : null;
    },
  },
  methods: {
    // onToggleAllPoints() {
    //   console.log('onToggleAllPoints');
    //   if (this.toggleAllPoints) {
    //     /// const maxPointAmount = Math.min(this.selectedPoint.redeemableDollars, this.amount);
    //     this.consumptionData.amount = this.selectedPoint.redeemableDollars;
    //   }
    // },
    onPointSelected(point) {
      console.log('onPointSelected' + JSON.stringify(point));
      this.selectedPoint = Object.assign({}, point);
      // reset point usage setting
      // this.toggleAllPoints = false;
      this.consumptionData.amount = 0;
      // this.$nextTick(() => {
      //   this.$refs.pointAmount.focus();
      // });
    },
    onPointAmountChange(value) {
      this.consumptionData.amount = value;
    },
    onKeyPressed(_key) {
      if (_key === 'del') {
        // this.consumptionData.use.pop();
        this.consumptionData.amount = Math.floor(
          this.consumptionData.amount / 10
        );
      } else if (_key === 'clear') {
        // this.consumptionData.use = [];
        this.consumptionData.amount = 0;
      } else if (_key >= 0) {
        // this.consumptionData.use.push(_key);
        this.consumptionData.amount = this.consumptionData.amount * 10 + _key;
      }
    },
    popNotice(type, message, callback) {
      this.noticeType = type || 'warning';
      this.noticeMessage = message;
      var popNotice = document.getElementById('popNotice');
      var close = popNotice.getElementsByClassName('notice-close')[0];
      var submit = popNotice.getElementsByClassName('notice-submit')[0];
      close.onclick = function () {
        popNotice.style.display = 'none';
      };
      submit.onclick = function () {
        popNotice.style.display = 'none';
        if (callback) {
          callback();
        }
      };
      window.onclick = function (event) {
        if (event.target == popNotice) {
          popNotice.style.display = 'none';
        }
      };
      popNotice.style.display = 'flex';
    },
    popConfirm() {
      if (this.isTokenExpired) {
        this.popNotice('warning', '訂單已過期，請重新掃碼付款', this.rePay);
        return;
      }

      if (!this.checkBalance()) {
        this.popNotice('warning', this.$i18n.t('Insufficient Points'));
        return;
      }
      var popConfirm = document.getElementById('popConfirm');
      // var close = popConfirm.getElementsByClassName('close')[0];
      // var submit = popConfirm.getElementsByClassName('submit')[0];
      // var cancel = popConfirm.getElementsByClassName('cancel')[0];
      // let self = this;
      // close.onclick = function () {
      //   popConfirm.style.display = 'none';
      // };
      // cancel.onclick = function () {
      //   popConfirm.style.display = 'none';
      // };
      // submit.onclick = function () {
      //   popConfirm.style.display = 'none';
      //   self.confirm();
      // };
      // window.onclick = function (event) {
      //   if (event.target == popConfirm) {
      //     popConfirm.style.display = 'none';
      //   }
      // };
      popConfirm.style.display = 'flex';
    },
    popConfirmClose() {
      var popConfirm = document.getElementById('popConfirm');
      popConfirm.style.display = 'none';
    },
    popConfirmSubmit() {
      var popConfirm = document.getElementById('popConfirm');
      popConfirm.style.display = 'none';
      this.confirm();
    },
    checkBalance() {
      if (this.selectedPoint.availablePointBalance >= this.consumptionData.amount) {
        return true;
      } else {
        return false;
      }
    },
    // callUsePoints() {
    //   let that = this;
    //   var data = JSON.stringify({
    //     receiverEntityType: 'Store',
    //     receiverEntityId: this.storeInfo.resourceEntityId,
    //     points: this.consumptionData.amount,
    //     note: `${this.storeInfo.storeName}`,
    //   });

    //   var config = {
    //     method: 'post',
    //     url: `${this.apiHost}/points/v1/merchants/${this.merchantId}/members/${this.user.userId}/orders`,
    //     headers: {
    //       'Content-Type': 'application/json',
    //     },
    //     data: data,
    //   };
    //   this.spin = true;
    //   this.$http(config)
    //     .then((response) => {
    //       this.spin = false;
    //       console.log(JSON.stringify(response.data));
    //       //  使用replace，避免按手機回上一頁時，跳到付款頁
    //       that.$router.replace({
    //         path: '/consumption-result',
    //         query: response.data,
    //       });
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //       this.spin = false;
    //     });
    // },
    pay() {
      // let that = this;
      var data = {
        memberId: this.userInfo.memberId,
        points: {
          pointCode: this.selectedPoint.pointCode,
          pointName: this.selectedPoint.pointName,
          points: this.usedPoints,
          amount: this.consumptionData.amount,
          pointsToCurrency: this.selectedPoint.pointsToCurrency,
          // "pointsToCurrency": {
          //     "points":1,
          //     "currency":1
          // }
        },
        payment: null,
        merchantOrderId: this.orderInfo.merchantOrderId,
        billingInfo: {
          name: this.userInfo.name,
          phone: this.userInfo.phoneNumber || "0987654321",
          email: this.userInfo.email || "service@dotdot.cc"
        },
        orderInfo: {
          amount: this.consumptionData.amount,
        },
      };
      var config = {
        method: 'post',
        url: `${this.apiHost}/payment/v1/merchants/${this.merchantId}/orders/${this.token}/pay`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: data,
      };
      this.spin = true;
      this.$http(config)
        .then((response) => {
          this.spin = false;
          console.log(JSON.stringify(response.data));
          //  使用replace，避免按手機回上一頁時，跳到付款頁
          this.$router.replace({
            path: '/consumption-result',
            query: { orderId: response.data.orderId },
          });
        })
        .catch((e) => {
          console.log(e);
          let message = e.response?.data?.error?.message || e.response?.data?.message; 
      
          if (!message) {
            message = '付款失敗，請稍後重試';
          }
          this.popNotice('error', message);
          this.spin = false;
        });
    },
    confirm() {
      // spin 在confirm時擋，避免重複付點
      if (this.spin) return;
      this.spin = true;
      
      if (this.checkBalance()) {
        // this.callUsePoints();
        this.pay();
      } else {
        this.popNotice('warning', this.$i18n.t('Insufficient Points'));
        this.spin = false;
      }
    },
    rePay() {
      this.$router.push('/scanner');
    },
    getUserPoints() {
      var config = {
        method: 'get',
        url: `${this.apiHost}/points/v1/merchants/${this.merchantId}/members/${this.user.userId}/points`,
        headers: {},
      };

      return this.$http(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          return response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getAppointedStoreInfo() {
      console.log(this.storeCode);
      let that = this;
      var config = {
        method: 'get',
        url: `${this.apiHost}/stores/v1/merchants/${this.merchantId}/store-codes/${this.storeCode}`,
        headers: {},
      };

      return this.$http(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          return response.data;
        })
        .catch(function (error) {
          console.log(error);
          that.$router.push('/home');
        });
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/');
    },
    getOrderInfoByToken(token) {
      var config = {
        method: 'get',
        url: `${this.apiHost}/payment/v1/merchants/${this.merchantId}/orders/${token}`,
        headers: {},
      };

      return this.$http(config);
    },
  },
};
</script>

<style src="@/assets/css/app.css"></style>
<style src="@/assets/css/unique.css"></style>
<style scoped src="@/assets/css/payment.css"></style>
